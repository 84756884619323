import { Component } from '@angular/core';

@Component({
  selector: 'as-page-container',
  template: `
    <as-nav-bar></as-nav-bar>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <as-footer-bar></as-footer-bar>
  `,
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent {}
