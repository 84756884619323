// Angular
import { Component } from '@angular/core';

@Component({
  selector: 'as-contact-page',
  template: `
    <div class="page-padding">
      <h2>Contact Us</h2>
      <p>
        To find out more information and to book your space please contact Amber
        at
        <a href="mailto:swim@allstrokes.co.uk" target="_blank">
          swim@allstrokes.co.uk
        </a>
        or on 07746619891. We look forward to hearing from you!
      </p>

      <p>
        Stay up to date by following us on
        <a href="https://www.facebook.com/allstrokesswimschool/">Facebook</a>
        and <a href="https://www.instagram.com/all.strokes.swim/">Instagram</a>.
      </p>

      <h2>Policies</h2>

      <p>
        You can find our safeguarding policy
        <a
          href="/assets/documents/all_strokes_safeguarding_policy.pdf"
          target="_blank"
          >here</a
        >.
      </p>
    </div>
  `,
})
export class ContactPageComponent {}
