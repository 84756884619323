import { Component, Input } from '@angular/core';

@Component({
  selector: 'as-content-image',
  template: `
    <div class="container">
      <img src="{{ 'assets/images/' + filename }}" />
    </div>
  `,
  styleUrls: ['./content-image.component.scss']
})
export class ContentImageComponent {
  @Input()
  filename: string;
}
