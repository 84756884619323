// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import {
  ContactPageComponent,
  LandingPageComponent,
  LessonsPageComponent,
  MermaidPageComponent,
  StagesPageComponent,
  TermDatesPageComponent,
  VenuesPageComponent,
} from './pages';

// Routes
import {
  HOME_ROUTE,
  LESSONS_ROUTE,
  MERMAID_ROUTE,
  CONTACT_ROUTE,
  STAGES_ROUTE,
  TERM_DATES_ROUTE,
  VENUES_ROUTE,
} from './routes';

const routes: Routes = [
  {
    path: HOME_ROUTE,
    component: LandingPageComponent,
  },
  {
    path: LESSONS_ROUTE,
    component: LessonsPageComponent,
  },
  {
    path: MERMAID_ROUTE,
    component: MermaidPageComponent,
  },
  {
    path: CONTACT_ROUTE,
    component: ContactPageComponent,
  },
  {
    path: STAGES_ROUTE,
    component: StagesPageComponent,
  },
  {
    path: TERM_DATES_ROUTE,
    component: TermDatesPageComponent,
  },
  {
    path: VENUES_ROUTE,
    component: VenuesPageComponent,
  },
  {
    path: '',
    redirectTo: HOME_ROUTE,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: HOME_ROUTE,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
