import { Component } from '@angular/core';

@Component({
  selector: 'as-venues-page',
  template: `
    <div class="page-padding">
      <h2>Venues</h2>

      <p>
        We use a number of venues across Surrey and Berkshire. All of our pools
        are heated.
      </p>

      <ul>
        <li>Woking</li>
        <li>Chertsey</li>
        <li>Staines</li>
        <li>Egham</li>
        <li>Twyford</li>
        <li>Addlestone Moor (currently under refurbishment)</li>
      </ul>

      <as-content-image-group [filenames]="filenames"></as-content-image-group>
    </div>
  `,
})
export class VenuesPageComponent {
  readonly filenames: string[] = ['photos/woking.jpg', 'photos/staines.jpg'];
}
