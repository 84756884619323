import { Component, Input } from '@angular/core';

@Component({
  selector: 'as-announcement',
  template: `
    <div [ngClass]="mode" class="box">
      <h2 class="icon">
        <mat-icon
          class="font-awesome-icon"
          fontSet="fa"
          [fontIcon]="icons[mode] || icons['good']">
        </mat-icon>
      </h2>
      <h3>
        <ng-content></ng-content>
      </h3>
  </div>
  `,
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent {
  @Input()
  mode: 'warning' | 'good' = 'good';

  icons = {
    good: 'fa-exclamation-circle',
    warning: 'fa-exclamation-triangle',
  };
}
