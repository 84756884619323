import { Component } from '@angular/core';

@Component({
  selector: 'as-facebook-feed',
  template: `
    <div class="container">
      <div
        class="fb-page"
        data-href="https://www.facebook.com/allstrokesswimschool"
        data-tabs="timeline"
        data-width="500"
        data-height="600"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true">

        <blockquote
          cite="https://www.facebook.com/allstrokesswimschool"
          class="fb-xfbml-parse-ignore">

          <a href="https://www.facebook.com/allstrokesswimschool">
            All Strokes Swim School
          </a>
        </blockquote>
      </div>
    </div>
  `,
  styleUrls: ['./facebook-feed.component.scss']
})
export class FacebookFeedComponent {}
