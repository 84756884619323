import { Component } from '@angular/core';

@Component({
  selector: 'as-stages-page',
  template: `
    <div class="page-padding">
      <h2>Our Swim Stages</h2>

      <h3>Pre-School</h3>
      <p>
        Suitable for children aged 3-4 years old. During this class our focus is
        to build water confidence and learn basic kicking and movement techniques,
        leading to starting to swim independently. Children will also gain an
        understanding of water safety.
      </p>

      <h3>Stage 1</h3>
      <p>
        This stage helps build water confidence and float work on the back and
        front. Children will become confident at putting their faces in the water.
      </p>

      <h3>Stage 2</h3>
      <p>
        This stage works on children being able to swim 5 metres on their front
        and back without aids whilst developing streamlining. Swimmers will
        develop a strong leg kick and learn rotations, entries and aquatic
        breathing.
      </p>

      <h3>Stage 3</h3>
      <p>
        Children will work on swimming 10 metres on their front and back (without
        aids) with recognisable technique. Children will work on underwater
        streamlining and improving rotation skills. Water safety knowledge will
        be developed further.
      </p>

      <h3>Stage 4</h3>
      <p>
        In this stage children will learn and perfect the kicks of all 4 strokes
        and improve their overall technique. Push and glides and sculling will
        also be taught. Swimmers will gain an understanding of buoyancy through
        a range of skills. Strokes must be performed to Swim England’s expected
        standards.
      </p>

      <h3>Stage 5</h3>
      <p>
        This stage will develop treading water and sculling skills. The pull and
        technique for all four strokes will be developed and swum to Swim
        England's expected standards.
      </p>

    </div>
  `
})
export class StagesPageComponent {}
