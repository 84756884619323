import { Component } from '@angular/core';

@Component({
  selector: 'as-app-root',
  template: `
    <as-page-container>
      <router-outlet></router-outlet>
    </as-page-container>
  `
})
export class AppComponent {}
