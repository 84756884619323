import { Component } from '@angular/core';

@Component({
  selector: 'as-mermaid-page',
  template: `
    <img class="splash-image" src="assets/images/mermaid_logo_splash.jpg" />

    <div class="page-padding">
      <h2>Mermaid Swimming</h2>

      <p>
        Our sister company
        <a target="_blank" href="https://www.facebook.com/MermaidBayExperiences/">
          Mermaid Bay Experiences
        </a>
        offers you the chance to
        transform into a magical mermaid/man. We run birthday parties and
        experience sessions in Berkshire, Surrey and surrounding counties.
      </p>
      <p>
        These sessions in our magical Merkingdom include learning to swim safely
        in a mermaid tail, mermaid-themed games, pictures taken underwater and
        on poolside, and much more. Each session is run by a qulaified STA Mermaid
        and Mono Fin instructor, and each mermaid/man receives a certificate
        proving their merskills. Please contact us for more information on these
        fun and exciting sessions.
      </p>

      <as-content-image filename="photos/mermaid5.jpg"></as-content-image>
    </div>
  `
})
export class MermaidPageComponent {}
