export const CONTACT_ROUTE = 'contact';
export const HOME_ROUTE = 'home';
export const LESSONS_ROUTE = 'lessons';
export const MERMAID_ROUTE = 'mermaids';
export const STAGES_ROUTE = 'stages';
export const TERM_DATES_ROUTE = 'term-dates';
export const VENUES_ROUTE = 'venues';

export const NAV_OPTIONS = [
  { url: HOME_ROUTE, text: 'Home' },
  { url: LESSONS_ROUTE, text: 'Lessons' },
  { url: STAGES_ROUTE, text: 'Swim Stages' },
  { url: TERM_DATES_ROUTE, text: 'Term Dates' },
  { url: MERMAID_ROUTE, text: 'Mermaids' },
  { url: VENUES_ROUTE, text: 'Venues' },
  { url: CONTACT_ROUTE, text: 'Contact ' },
];
