import { Component } from '@angular/core';

@Component({
  selector: 'as-map',
  template: `
    <iframe
      [src]="mapIframeLink | trustResource"
      class="map"
      width="100%"
      height="500"
      frameborder="0"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0">
    </iframe>
  `,
  styleUrls: ['./map.component.scss']
})
export class MapComponent {
  mapIframeLink = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.1'
    + '225628677034!2d-0.4914028480894543!3d51.3824254275532!2m3!1f0!2f0!3f0!3m'
    + '2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48767667114e08af%3A0xa6dc3b96e2e55736!'
    + '2sPhilip%20Southcote%20Secondary%20School!5e0!3m2!1sen!2suk!4v1590929756'
    + '043!5m2!1sen!2suk';
}
