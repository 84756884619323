// Angular
import { Component, Input } from '@angular/core';

/**
 * For best results, use two images of equal aspect ratio. We might support more
 * dynamic behaviour in the future, but the max-width of the content container
 * means three or more images will be tiny.
 */
@Component({
  selector: 'as-content-image-group',
  template: `
    <div class="container">
      <ng-container *ngFor="let filename of filenames">
        <as-content-image [filename]="filename"></as-content-image>
      </ng-container>
    </div>
  `,
  styleUrls: ['./content-image-group.component.scss']
})
export class ContentImageGroupComponent {
  @Input()
  filenames: string[];
}
