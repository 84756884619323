// Angular
import { Component } from '@angular/core';

// Helpers
import { HOME_ROUTE, NAV_OPTIONS } from 'src/app/routes';

@Component({
  selector: 'as-nav-bar',
  template: `
    <mat-toolbar class="toolbar" color="primary">

      <!-- All Strokes logo button -->
      <as-nav-logo-button class="logo"></as-nav-logo-button>

      <span class="nav-container">

        <!-- Menu for low-width displays -->
        <button
          mat-icon-button
          class="nav-menu"
          [matMenuTriggerFor]="menu">
          <mat-icon class="nav-menu-button">menu</mat-icon>
        </button>

        <!-- Dropdown options in menu -->
        <mat-menu #menu="matMenu">
          <as-menu-button
            *ngFor="let button of buttons"
            [url]="button.url"
            [text]="button.text">
          </as-menu-button>
        </mat-menu>

        <!-- Buttons for high-width displays -->
        <as-nav-button
          *ngFor="let button of buttons"
          class="nav-buttons"
          [url]="button.url"
          [text]="button.text">
        </as-nav-button>

      </span>
    </mat-toolbar>
  `,
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  homeRoute = HOME_ROUTE;
  buttons = NAV_OPTIONS;
}
