import { Component } from '@angular/core';

@Component({
  selector: 'as-landing-page',
  template: `
    <div class="page-padding">
      <as-announcement mode="good">
        We are interested in expanding to new locations. If you have a pool and
        might like to hire it to us, or if you know someone who does, please get
        in touch.
      </as-announcement>

      <h2>Welcome to All Strokes!</h2>

      <p>
        We are a private swim school running high quality lessons in Surrey and
        Berkshire. Swimming is an invaluable life skill that we believe everyone
        should have the opportunity to learn.
      </p>

      <p>
        Our lessons are fun and enjoyable and held in a safe environment. We
        teach children from complete beginners to advanced. Due to small class
        ratios we can give individual attention to each child.
      </p>

      <p>We offer:</p>

      <ul>
        <li>Small group classes for children aged 3 upwards</li>
        <li>Parent & baby / toddler</li>
        <li>Private 1:1 and 2:1 lessons</li>
        <li>Adult private lessons</li>
      </ul>

      <p>
        Our weekly lessons are each 30 minutes long and run in line with school
        term times. We do offer holiday crash courses so please get in touch for
        more information on these!
      </p>

      <p>
        Our friendly teachers are all experienced and fully qualified with
        either ASA or STA qualifications.
      </p>

      <p>Enjoy learning to swim at our friendly swim school!</p>

      <as-content-image-group [filenames]="filenames"> </as-content-image-group>

      <as-facebook-feed></as-facebook-feed>
    </div>
  `,
})
export class LandingPageComponent {
  readonly filenames = ['photos/boy.jpg', 'photos/boy_and_girl.jpg'];
}
