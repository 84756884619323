// Angular
import { Component, Input } from '@angular/core';

@Component({
  selector: 'as-menu-button',
  template: `
    <a class="label" [href]="url">
      <button mat-menu-item>
        {{ text }}
      </button>
    </a>
  `,
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent {
  @Input() url: string;
  @Input() text: string;
}
