// Angular
import { Component, Input } from '@angular/core';

@Component({
  selector: 'as-nav-button',
  template: `
    <a mat-button
      class="enlarged"
      [href]="url">
      {{ text }}
    </a>
  `,
  styleUrls: ['./nav-button.component.scss']
})
export class NavButtonComponent {
  @Input() url: string;
  @Input() text: string;
}
