// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Warning: use only for well-trusted resources.
 *
 * Bypasses DomSanitizer checks for the URL in question.
 */
@Pipe({name: 'trustResource'})
export class TrustResourcePipe implements PipeTransform {
  constructor(public sanitizer: DomSanitizer) {}

  transform(value: any, ...args: any[]) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
