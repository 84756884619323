// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Angular Material
import { MaterialModule } from './material.module';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Pages
import {
  ContactPageComponent,
  LandingPageComponent,
  LessonsPageComponent,
  MermaidPageComponent,
  StagesPageComponent,
  TermDatesPageComponent,
  VenuesPageComponent,
} from './pages';

// Components
import { AppComponent } from './app.component';
import {
  AnnouncementComponent,
  ContentImageComponent,
  ContentImageGroupComponent,
  FacebookFeedComponent,
  FooterBarComponent,
  HeaderComponent,
  MapComponent,
  MenuButtonComponent,
  NavBarComponent,
  NavButtonComponent,
  NavLogoButtonComponent,
  PageContainerComponent,
} from './components';

// Pipes
import { TrustResourcePipe } from './pipes/trust-resource.pipe';

@NgModule({
  declarations: [
    // App root
    AppComponent,

    // Pages
    ContactPageComponent,
    LandingPageComponent,
    LessonsPageComponent,
    MermaidPageComponent,
    StagesPageComponent,
    TermDatesPageComponent,
    VenuesPageComponent,

    // Components
    AnnouncementComponent,
    ContentImageComponent,
    ContentImageGroupComponent,
    FacebookFeedComponent,
    FooterBarComponent,
    HeaderComponent,
    MapComponent,
    MenuButtonComponent,
    NavBarComponent,
    NavButtonComponent,
    NavLogoButtonComponent,
    PageContainerComponent,

    // Pipes
    TrustResourcePipe,
  ],
  imports: [BrowserModule, AppRoutingModule, MaterialModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
