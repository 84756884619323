import { Component } from '@angular/core';

@Component({
  selector: 'as-term-dates-page',
  template: `
    <div class="page-padding">
      <h2>Term Dates</h2>

      <h3>After-School Lessons</h3>

      <h4>Winter Term 2024</h4>

      <p>Our winter term lessons are all held at indoor pools.</p>

      <b>Tuesdays</b>

      <p>
        3:45 - 5:15 PM (Chertsey)<br />
        6:00 - 8:00 PM (Woking)<br />
        5th November - 10th December (6 weeks)
      </p>

      <b>Thursdays</b>
      <p>
        4:00 - 6:00 PM (Chertsey)<br />
        7th November - 12th December (6 weeks)
      </p>

      <h3>Parent & Baby / Toddler</h3>

      <h4>Autumn/Winter Term 2024</h4>

      <b>Wednesdays</b>

      <p>
        9:30 - 11:30 AM<br />
        11th September - 4th December (11 weeks)<br />
        No swimming 23rd or 30th October
      </p>
    </div>
  `,
})
export class TermDatesPageComponent {}
