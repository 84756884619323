// Angular
import { Component } from '@angular/core';

// Helpers
import { HOME_ROUTE } from 'src/app/routes';

@Component({
  selector: 'as-nav-logo-button',
  template: `
    <a [href]="homeRoute">
      <img class="logo" src="assets/images/logo.png" />
    </a>
  `,
  styleUrls: ['./nav-logo-button.component.scss']
})
export class NavLogoButtonComponent {
  readonly homeRoute = HOME_ROUTE;
}
