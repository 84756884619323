// Angular
import { Component } from '@angular/core';

@Component({
  selector: 'as-footer-bar',
  template: `
    <mat-toolbar color="accent">
      <div class="container">
        <a href="https://www.instagram.com/all.strokes.swim/" class="icon">
          <i class="fa-brands fa-square-instagram"></i>
        </a>
        <a href="https://www.facebook.com/allstrokesswimschool/" class="icon">
          <i class="fa-brands fa-square-facebook"></i>
        </a>
        <span class="copyright">
          &#169; 2020 - {{ year }} All Strokes Swim School
        </span>
      </div>
    </mat-toolbar>
  `,
  styleUrls: ['./footer-bar.component.scss'],
})
export class FooterBarComponent {
  year: number = new Date().getFullYear();
}
